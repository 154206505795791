// Libs
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Components
import { device } from '../device';

// Images
import BackIcon from 'images/icons/arrow-left.svg';

// Context
import { HeaderContext } from 'context/header';

// Styled
const HeaderGridArea = styled.div`
  display: none;

  @media ${device.laptop} {
    grid-area: HD;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Container = styled.header`
  @media ${device.laptop} {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    height: 7.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    background: #fff;
  }

  @media ${device.mobile} {
    padding: 1rem;
    height: 6rem;
  }
`;

const ContainerNav = styled.nav`
  margin-left: 0;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

const Img = styled.img`
  padding-right: ${props => props.paddingRight};
  width: 1.5rem;

  @media ${device.mobile} {
    margin-right: 1.3rem;
    min-width: 1.5rem;
    min-height: 1.5rem;

    ${props =>
      props.callCenterMob &&
      `
        margin-right: 0;  
    `};
  }
`;

const ContainerCallCenterMob = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      font-size: 1rem;
      color: #231f20;
    }
  }
`;

function HeaderIntern({ modalBackLink }) {
  const { headerText, callCenterBackLink } = useContext(HeaderContext);

  const renderCallCenterMobile = () => (
    <ContainerCallCenterMob>
      <Link to={modalBackLink ? '' : callCenterBackLink}>
        <Img src={BackIcon} alt="Voltar" callCenterMob />
      </Link>
      <div>
        <p>{headerText}</p>
      </div>
    </ContainerCallCenterMob>
  );

  return (
    <HeaderGridArea id="teste">
      <Container>
        <ContainerNav>{renderCallCenterMobile()}</ContainerNav>
      </Container>
    </HeaderGridArea>
  );
}

export default HeaderIntern;
