// Libs
import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from '../../../context/header';
import { FooterContext } from '../../../context/footer';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import DescriptionPage from 'components/contactUs/descriptionPage';
import Contact from 'components/contactUs/contact';
import Menu from 'components/contactUs/menu';
import HeaderIntern from 'components/contactUs/headerIntern';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  @media ${device.laptop} {
    display: block;
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`;
const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;

  section {
    img {
      :first-child {
        position: relative;
        top: 19px;
      }
    }
  }

  section {
    p {
      a:nth-child(2n -1) {
        img {
          margin-right: 1.25rem;
          top: 6px;
        }
      }
      :nth-child(4n -2) {
        padding: 1.625rem 1.25rem;
      }
    }
  }

  @media ${device.laptop} {
    padding: 0 1.25rem;
    display: flex;

    p {
      width: 100%;
      font-size: 0.875rem;
      em {
        font-size: 0.875rem;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];

  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const CallCenter = ({ page, location }) => {
  const { setHasDefaultHeader, setCallCenterBackLink } = useContext(
    HeaderContext
  );

  const { setHasFooter } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/');
      setHasFooter(false);
    }
  }, []);

  const pathname = location.pathname;

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'cki503rx40xyw0a25tx76v77e', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <DescriptionPage
            markdown={getMarkdown(page, 'cki50cxe80yq70a29gzqnv98k', true)}
            attendanceScreen
            shortDescription
          />
          <Contact
            markdown={getMarkdown(page, 'cki55otko1hg60a71s30gdq5b', true)}
            isFaq
            attendanceScreen
          />
          <Menu intern pathname={pathname} />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "cki4z8obk0vcx0a71l9gy4eqr" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <CallCenter page={response.gcms.site.pages} location={location} />
        );
      }}
    />
  );
};
