// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../device';

// Styles
const Content = styled.section`
  width: 100%;
  height: auto;
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
    flex-direction: ${props => props.isConsultationOrExam && 'column'};
  }

  ${({ schedulingConsultationsExams }) =>
    schedulingConsultationsExams &&
    css`
      display: flex;
      flex-wrap: wrap;

      @media ${device.laptop} {
        flex-direction: column;
        flex-wrap: unset;
      }
    `}

  h3 {
    display: ${props => (props.attendanceScreen ? 'none' : 'block')};
    font-weight: 400;
    font-size: 1.25rem;
    border-top: ${props => props.scheduling && '1px solid #C6C8CC'};
    padding-top: ${props => props.scheduling && '3.125rem'};

    @media ${device.laptop} {
      font-size: 1.125rem;
      padding-top: ${props => props.scheduling && '1.875rem'};
    }

    ${({ schedulingConsultationsExams }) =>
      schedulingConsultationsExams &&
      css`
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.875rem;
        color: #231f20;

        @media ${device.laptop} {
          font-size: 1.125rem;
          line-height: 1.625rem;
          margin-bottom: 1.25rem;
        }
      `}
  }

  h2 {
    border-top: 1px solid #c6c8cc;
    padding-top: ${props => (props.marginBottom ? '3.125rem' : '1.875rem')};
    font-size: 1.25rem;
    color: #231f20;
    font-weight: 400;

    @media ${device.laptop} {
      font-size: 1.125rem;
      padding-top: ${props => props.paddingTop && '1.875rem'};
    }
  }

  > p {
    border: 1px solid #c6c8cc;
    font-size: 1rem;
    margin-bottom: ${props => (props.shoulderElbowPage ? '0px' : '0.625rem')};
    padding: 12px 1.25rem 1.25rem;
    padding-top: ${props => props.shoulderElbowPage && '.8rem'};
    border-radius: 8px;
    height: auto;
    color: #8f9194;

    @media ${device.laptop} {
      font-size: 0.875rem;
    }

    :first-child {
      margin-bottom: ${props => props.marginBottom && '3.125rem'};

      @media ${device.laptop} {
        margin-bottom: ${props => props.marginBottom && '1.875rem'};
      }
    }

    :nth-child(2n -2) {
      padding: ${props => props.shoulderElbowPage && '0'};
      padding-top: ${props => props.shoulderElbowPage && '3.125rem'};
      border: ${props => props.shoulderElbowPage && 'none'};
      color: ${props => props.shoulderElbowPage && '#231F20'};

      @media ${device.laptop} {
        padding: ${props => props.shoulderElbowPage && '1.875rem 0 0 0'};
      }
    }

    > a:nth-child(2n -1) {
      margin-left: ${props => (props.isConsultationOrExam ? '0' : '-2.8rem')};
      line-height: ${props => !props.noLineHeight && '2rem'};
      top: ${props => props.isConsultationOrExam && '-8px'};
      position: ${props => props.isConsultationOrExam && 'relative'};
    }

    :nth-child(3n -3) {
      margin-bottom: ${props => props.scheduling && '3.125rem'};
    }

    :last-child {
      margin-bottom: 0;
      border: ${props => (props.notBorder ? 'unset' : '1px solid #c6c8cc')};
      padding: ${props => props.notBorder && '1.875rem 0 0'};
      margin-top: ${props => props.isConsultationOrExam && '0.625rem'};
    }

    > img {
      width: 1.5rem;
      margin-right: 1rem;
      position: relative;
      top: 8px;
      margin-top: ${props => props.isConsultationOrExam && '-10px'};
    }

    > strong {
      font-weight: 600;
      line-height: 1.5rem;
      color: #231f20;
    }

    > a {
      display: inline-block;
      line-height: ${props =>
        props.linkLineHeight
          ? props.linkLineHeight
          : !props.internationalPage && '1.5rem'};
      padding-left: 2.8rem;
      color: #45a7df;
      ${props =>
        props.linkLineHeight &&
        css`
          margin-bottom: 1rem;
        `};
    }

    a:first-of-type {
      line-height: 1.5rem;
    }

    > em {
      font-size: 1rem;
      @media ${device.mobile} {
        font-size: 0.875rem;
      }
      padding-left: ${props => !props.ombudsmanPage && '2.8rem'};
      font-style: normal;
      line-height: ${props => !props.noLineHeight && '2rem'};
      position: ${props => props.ombudsmanPage && 'relative'};
      top: ${props => props.ombudsmanPage && '5px'};

      @media ${device.laptop} {
        line-height: 1.375rem;
      }
    }

    ${({ schedulingConsultationsExams }) =>
      schedulingConsultationsExams &&
      css`
        width: 100%;
        max-width: ${({ schedulingConsultationsExamsMaxWidth }) =>
          schedulingConsultationsExamsMaxWidth
            ? schedulingConsultationsExamsMaxWidth
            : '35.625rem'};
        line-height: 1.625rem;

        margin: 0;
        padding: 1.875rem 1.875rem 1.875rem 4.5rem;

        img {
          width: 1.5rem;
          height: auto;
          margin-right: 1rem;
          margin-left: -2.5rem;
          position: relative;
          top: 0.25rem;
        }

        a {
          padding-left: 0 !important;
          margin-left: 0 !important;
        }

        strong + br {
          content: '';
          display: block;
          font-size: 100%;
          height: 0.5rem;
          display: block;
        }

        br + strong {
          display: inline-block;
          margin-top: 0.75rem;
        }

        > em {
          font-size: 1rem;
          line-height: 1.375rem;
          display: inline-block;
          padding: 0;
          margin-top: 0.75rem;

          a {
            color: #45a7df;
          }
        }

        & + p {
          margin-left: 1.875rem;
        }

        @media ${device.laptop} {
          font-size: 0.875rem;
          line-height: 1.375rem;

          img {
            top: 0.4rem;
          }

          > em {
            font-size: 0.875rem;
            line-height: 1.375rem;
            width: 100%;
          }

          & + p {
            margin-left: unset;
            margin-top: 1.25rem;
          }
        }
      `}
  }
`;

function LinkRenderer({ href, children }) {
  const handleClick = () => {
    if (href === '/faq/paciente') {
      localStorage.setItem('page', 'portal-do-paciente');
    }
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      target={props => (props.isFaq ? '_self' : '_blank')}
    >
      {children}
    </a>
  );
}

const Contact = ({
  markdown,
  scheduling,
  marginBottom,
  paddingTop,
  attendanceScreen,
  shoulderElbowPage,
  ombudsmanPage,
  internationalPage,
  padding,
  noLineHeight,
  notBorder,
  isConsultationOrExam,
  linkLineHeight,
  schedulingConsultationsExams,
  schedulingConsultationsExamsMaxWidth,
}) => (
  <Content
    isConsultationOrExam={isConsultationOrExam}
    scheduling={scheduling}
    marginBottom={marginBottom}
    paddingTop={paddingTop}
    attendanceScreen={attendanceScreen}
    shoulderElbowPage={shoulderElbowPage}
    ombudsmanPage={ombudsmanPage}
    internationalPage={internationalPage}
    padding={padding}
    noLineHeight={noLineHeight}
    notBorder={notBorder}
    linkLineHeight={linkLineHeight}
    schedulingConsultationsExams={schedulingConsultationsExams}
    schedulingConsultationsExamsMaxWidth={schedulingConsultationsExamsMaxWidth}
  >
    <ReactMarkdown renderers={{ link: LinkRenderer }} children={markdown} />
  </Content>
);

export default Contact;
