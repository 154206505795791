// Libs
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../device';

const Container = styled.div`
  width: auto;
  height: auto;
`;

const Content = styled.div`
  width: auto;
  height: auto;

  > h1 {
    margin-bottom: ${props => props.titleMarginBottom};
    width: ${props =>
      props.contentWidth
        ? props.contentWidth
        : props.interScreen
        ? '95%'
        : '90%'};
    font-size: 3.375rem;
    padding-bottom: ${props =>
      props.titlePaddingBottom ? props.titlePaddingBottom : '1.25rem'};
    line-height: 3.75rem;
    color: #231f20;
    font-weight: bold;

    ${props =>
      props.titleWidthLargeDesk &&
      `
      width: 100%;
    `}

    @media ${device.laptop} {
      padding-top: ${props => !props.isBlog && '1.9375rem'};
      padding-bottom: ${props => props.paddingBottom && '1.9375rem'};
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
    @media ${device.mobile} {
      padding-top: 0.5rem;
    }

    @media ${device.mobile320} {
      width: ${props => props.interScreen && '92%'};
    }
  }

  > h2:first-child {
    @media ${device.laptop} {
      padding-top: 1.9375rem;
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
    @media ${device.mobile} {
      padding-top: 0.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  > ul {
    display: ${props => props.isBlog && 'none'};
  }

  > p {
    width: ${props =>
      props.widthLarge ? '100%' : props.shortDescription ? '93%' : '75%'};
    font-size: 1rem;
    padding-bottom: ${props =>
      props.complianceScreen ? '1.375rem' : '3.125rem'};
    line-height: 1.625rem;

    :nth-child(3n + 0) {
      padding-bottom: 3.125rem;
    }

    @media ${device.laptop} {
      padding-bottom: 1.875rem;

      :nth-child(3n + 0) {
        padding-bottom: 1.875rem;
      }
    }

    @media ${device.mobile} {
      font-size: 0.875rem;
      padding-bottom: ${props =>
        props.complianceScreen ? '1.875rem' : '2.0625rem'};
    }

    :last-child {
      color: ${props =>
        props.isBlog
          ? '#8f9194 !important'
          : props.attendanceScreen
          ? '#8F9194'
          : '#231F20'};

      display: flex;
      align-items: center;
      padding-bottom: ${props =>
        props.complianceScreen ? '4.375rem' : '3.125rem'};

      @media ${device.laptop} {
        padding-bottom: ${props =>
          props.responsiveWithoutPaddingBottom ? '0' : '1.875rem'};
      }

      @media ${device.mobile} {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }

    > img {
      width: 2rem;
      padding-right: 0.625rem;
    }

    em {
      color: ${props => props.complianceScreen && '#FFF'};
      background-color: ${props => props.complianceScreen && '#45A7DF'};
      padding: 0.72rem;
      border-radius: 23px;
      width: ${props => props.complianceScreen && '14.563rem'};
      text-align: ${props => props.complianceScreen && 'center'};

      strong {
        font-size: 0.875rem;
        font-weight: 400;
      }

      @media ${device.laptop} {
        font-size: 0.875rem;
        padding-top: ${props => props.scheduling && '1.875rem'};
        width: 100%;
        text-align: center;
      }
    }
  }
`;

function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

const DescriptionPage = ({
  markdown,
  widthLarge,
  complianceScreen,
  interScreen,
  attendanceScreen,
  titleWidthLargeDesk,
  shortDescription,
  titlePaddingBottom,
  paddingBottom,
  newTab,
  isBlog,
  responsiveWithoutPaddingBottom,
  dataPostBlog,
  contentWidth,
}) => {
  return (
    <Container>
      <Content
        widthLarge={widthLarge}
        interScreen={interScreen}
        complianceScreen={complianceScreen}
        attendanceScreen={attendanceScreen}
        titleWidthLargeDesk={titleWidthLargeDesk}
        shortDescription={shortDescription}
        titlePaddingBottom={titlePaddingBottom}
        paddingBottom={paddingBottom}
        isBlog={isBlog}
        responsiveWithoutPaddingBottom={responsiveWithoutPaddingBottom}
        contentWidth={contentWidth}
      >
        {isBlog ? (
          <>
            <h1>{dataPostBlog && dataPostBlog.title}</h1>
            {dataPostBlog.description && <p>{dataPostBlog.description}</p>}
          </>
        ) : (
          <ReactMarkdown
            renderers={newTab && { link: LinkRenderer }}
            children={markdown || '-'}
          />
        )}
      </Content>
    </Container>
  );
};

export default DescriptionPage;
